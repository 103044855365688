import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Trading/Form/TradeExecutionTypeButtons.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Trading/Form/OrderTypeSelect.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Trading/Form/OrderInputs.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Trading/Form/Debug.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Partials/Trading/OrderDetails/Index.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Partials/Trading/Form/OrderSubmit.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/Modals/OrderConfirm/Derivative.vue";
import { default as __nuxt_component_7 } from "/opt/build/repo/components/Modals/PriceDeviation.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "w-full flex flex-col gap-6" };
import {
  Status,
  StatusType,
  BigNumberInWei,
  BigNumberInBase
} from "@injectivelabs/utils";
import {
  MarketType,
  ZERO_IN_BASE,
  DerivativeOrderSide
} from "@injectivelabs/sdk-ui-ts";
import { TradeDirection } from "@injectivelabs/ts-types";
import { DerivativeOrderState } from "@injectivelabs/sdk-ts";
import {
  Modal,
  TradeField,
  TradeExecutionType,
  OrderAttemptStatus
} from "@/types";
import {
  DEBUG_CALCULATION,
  TRADE_FORM_PRICE_ROUNDING_MODE
} from "@/app/utils/constants";
import {
  calculateMargin,
  calculateLiquidationPrice,
  calculateBinaryOptionsMargin
} from "@/app/client/utils/derivatives";
import { amplitudeTracker } from "@/app/providers/AmplitudeTracker";
export default /* @__PURE__ */ _defineComponent({
  __name: "Form",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const appStore = useAppStore();
    const bankStore = useBankStore();
    const modalStore = useModalStore();
    const positionStore = usePositionStore();
    const derivativeStore = useDerivativeStore();
    const { success } = useNotifications();
    const { t } = useLang();
    const { $onError } = useNuxtApp();
    const { values, resetForm: resetFormValues } = useForm();
    const defaultStep = "1";
    const isBaseAmount = ref(true);
    const status = reactive(new Status(StatusType.Idle));
    const formValues = computed(() => values);
    const {
      baseAmount,
      limitPrice,
      triggerPrice,
      hasBaseAmount,
      hasTriggerPrice,
      tradingTypeLimit,
      tradingTypeMarket,
      isConditionalOrder,
      tradingTypeStopLimit,
      tradingTypeStopMarket
    } = useDerivativeFormFormatter(formValues);
    const { makerFeeRate, takerFeeRate } = useTradeFee(computed(() => props.market));
    const { markPrice, lastTradedPrice } = useDerivativeLastPrice(
      computed(() => props.market)
    );
    const amountStep = computed(() => {
      return props.market ? new BigNumberInBase(1).shiftedBy(props.market.quantityTensMultiplier).toFixed() : defaultStep;
    });
    const priceStep = computed(() => {
      return props.market ? new BigNumberInBase(1).shiftedBy(-props.market.priceDecimals).toFixed() : defaultStep;
    });
    const isBuy = computed(
      () => formValues.value[TradeField.OrderType] === DerivativeOrderSide.Buy
    );
    const orderTypeToSubmit = computed(() => {
      if (tradingTypeStopLimit.value || tradingTypeStopMarket.value) {
        const triggerPriceInBase = triggerPrice.value || ZERO_IN_BASE;
        return isBuy.value ? triggerPriceInBase.lt(markPrice.value) ? DerivativeOrderSide.TakeBuy : DerivativeOrderSide.StopBuy : triggerPriceInBase.gt(markPrice.value) ? DerivativeOrderSide.TakeSell : DerivativeOrderSide.StopSell;
      }
      switch (true) {
        case (formValues.value[TradeField.PostOnly] && isBuy.value): {
          return DerivativeOrderSide.BuyPO;
        }
        case isBuy.value: {
          return DerivativeOrderSide.Buy;
        }
        case (formValues.value[TradeField.PostOnly] && !isBuy.value): {
          return DerivativeOrderSide.SellPO;
        }
        case !isBuy.value: {
          return DerivativeOrderSide.Sell;
        }
        default: {
          return DerivativeOrderSide.Buy;
        }
      }
    });
    const {
      slippage,
      maxReduceOnly,
      maxAmountOnOrderbook,
      updateAmountFromBase,
      worstPriceWithSlippage
    } = useDerivativePrice({
      formValues,
      isBaseAmount,
      market: computed(() => props.market)
    });
    const showReduceOnly = computed(() => {
      if (isConditionalOrder.value) {
        const hasOpenOrder = derivativeStore.subaccountOrders.some(
          (order) => order.marketId === props.market.marketId && [
            DerivativeOrderState.PartialFilled,
            DerivativeOrderState.Unfilled,
            DerivativeOrderState.Booked
          ].includes(order.state)
        );
        return !!position.value || hasOpenOrder;
      }
      if (!position.value) {
        return false;
      }
      const longAndBuy = position.value.direction === TradeDirection.Long && isBuy.value;
      const shortAndSell = position.value.direction === TradeDirection.Short && !isBuy.value;
      return !(longAndBuy || shortAndSell);
    });
    const orderTypeReduceOnly = computed(
      () => formValues.value[TradeField.ReduceOnly] && showReduceOnly.value
    );
    const position = computed(() => {
      if (positionStore.subaccountPositions.length === 0) {
        return;
      }
      return positionStore.subaccountPositions.find(
        (position2) => position2.marketId === props.market.marketId
      );
    });
    const quoteAvailableBalance = computed(() => {
      const balance = bankStore.balanceMap[props.market.quoteDenom] || "0";
      const quoteAvailableBalance2 = new BigNumberInWei(balance).toBase(
        props.market.quoteToken.decimals
      );
      return quoteAvailableBalance2;
    });
    const feeRate = computed(() => {
      if (formValues.value[TradeField.PostOnly] && !tradingTypeMarket.value) {
        return makerFeeRate.value;
      }
      return takerFeeRate.value;
    });
    const executionPrice = computed(() => {
      return tradingTypeMarket.value || tradingTypeStopMarket.value ? worstPriceWithSlippage.value : limitPrice.value;
    });
    const hasExecutionPrice = computed(() => executionPrice.value.gt(0));
    const notionalWithLeverage = computed(() => {
      if (!hasBaseAmount.value) {
        return ZERO_IN_BASE;
      }
      if (!hasExecutionPrice.value && !tradingTypeStopMarket.value) {
        return ZERO_IN_BASE;
      }
      if (!triggerPrice.value && tradingTypeStopMarket.value) {
        return ZERO_IN_BASE;
      }
      const price = tradingTypeMarket.value || tradingTypeStopMarket.value ? worstPriceWithSlippage.value.toFixed() : executionPrice.value.toFixed();
      if (props.market.subType === MarketType.BinaryOptions) {
        return new BigNumberInBase(
          calculateBinaryOptionsMargin({
            price,
            orderSide: formValues.value[TradeField.OrderType],
            quantity: formValues.value[TradeField.BaseAmount],
            tensMultiplier: props.market.quantityTensMultiplier
          }).toFixed()
        );
      }
      return new BigNumberInBase(
        calculateMargin({
          price,
          quantity: formValues.value[TradeField.BaseAmount],
          tensMultiplier: props.market.quantityTensMultiplier,
          leverage: formValues.value[TradeField.Leverage]
        }).toFixed()
      );
    });
    const notionalWithLeverageBasedOnWorstPrice = computed(() => {
      if (!hasBaseAmount.value) {
        return ZERO_IN_BASE;
      }
      if (!hasExecutionPrice.value && !tradingTypeStopMarket.value) {
        return ZERO_IN_BASE;
      }
      if (!triggerPrice.value && tradingTypeStopMarket.value) {
        return ZERO_IN_BASE;
      }
      if (props.market.subType === MarketType.BinaryOptions) {
        return new BigNumberInBase(
          calculateBinaryOptionsMargin({
            price: worstPriceWithSlippage.value.toFixed(),
            orderSide: formValues.value[TradeField.OrderType],
            quantity: formValues.value[TradeField.BaseAmount],
            tensMultiplier: props.market.quantityTensMultiplier
          }).toFixed()
        );
      }
      return new BigNumberInBase(
        calculateMargin({
          price: worstPriceWithSlippage.value.toFixed(),
          leverage: formValues.value[TradeField.Leverage],
          quantity: formValues.value[TradeField.BaseAmount],
          tensMultiplier: props.market.quantityTensMultiplier
        }).toFixed()
      );
    });
    const notionalValue = computed(() => {
      if (baseAmount.value.isNaN()) {
        return ZERO_IN_BASE;
      }
      const price = tradingTypeMarket.value || tradingTypeStopMarket.value ? worstPriceWithSlippage.value.toFixed() : executionPrice.value.toFixed();
      const notional = baseAmount.value.times(price);
      if (notional.lt(0)) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInBase(notional);
    });
    const fees = computed(() => {
      if (notionalValue.value.isNaN()) {
        return ZERO_IN_BASE;
      }
      return notionalValue.value.times(feeRate.value);
    });
    const notionalWithLeverageToBigNumber = computed(() => {
      if (!hasBaseAmount.value) {
        return ZERO_IN_BASE;
      }
      if (!hasExecutionPrice.value && !tradingTypeStopMarket.value) {
        return ZERO_IN_BASE;
      }
      if (!triggerPrice.value && tradingTypeStopMarket.value) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInBase(notionalWithLeverage.value);
    });
    const notionalWithLeverageAndFees = computed(() => {
      if (notionalWithLeverageToBigNumber.value.isNaN() || notionalWithLeverageToBigNumber.value.lte(0)) {
        return ZERO_IN_BASE;
      }
      return fees.value.plus(notionalWithLeverageToBigNumber.value);
    });
    const liquidationPrice = computed(() => {
      if (!hasBaseAmount.value) {
        return ZERO_IN_BASE;
      }
      if (!hasExecutionPrice.value && !tradingTypeStopMarket.value) {
        return ZERO_IN_BASE;
      }
      if (!triggerPrice.value && tradingTypeStopMarket.value) {
        return ZERO_IN_BASE;
      }
      if (props.market.subType === MarketType.BinaryOptions) {
        return ZERO_IN_BASE;
      }
      const derivativeMarket = props.market;
      const price = tradingTypeMarket.value || tradingTypeStopMarket.value ? worstPriceWithSlippage.value.toFixed() : executionPrice.value.toFixed();
      return calculateLiquidationPrice({
        price,
        market: derivativeMarket,
        quantity: formValues.value[TradeField.BaseAmount],
        orderType: formValues.value[TradeField.OrderType],
        notionalWithLeverage: notionalWithLeverage.value.toFixed()
      });
    });
    const {
      highDeviation,
      maxOrdersError,
      availableBalanceError,
      markPriceThresholdError,
      initialMinMarginRequirementError
    } = useDerivativeError({
      isBuy,
      markPrice,
      formValues,
      executionPrice,
      orderTypeReduceOnly,
      notionalWithLeverage,
      quoteAvailableBalance,
      worstPriceWithSlippage,
      notionalWithLeverageAndFees,
      notionalWithLeverageBasedOnWorstPrice,
      market: computed(() => props.market)
    });
    watch(executionPrice, () => {
      if (maxAmountOnOrderbook.value.totalQuantity.eq(0)) {
        return;
      }
      updateAmount({ isBaseAmount: isBaseAmount.value });
    });
    watch(
      () => lastTradedPrice.value,
      (newPrice) => {
        if (tradingTypeStopLimit.value) {
          return;
        }
        const hasNoInputPrice = !hasExecutionPrice.value || executionPrice.value.lte(0);
        const hasLatestLastTradedPrice = newPrice.gt("0");
        if (hasNoInputPrice && hasLatestLastTradedPrice) {
          const formattedPrice = newPrice.toFixed(
            props.market.priceDecimals,
            TRADE_FORM_PRICE_ROUNDING_MODE
          );
          formValues.value[TradeField.LimitPrice] = formattedPrice;
        }
      },
      { immediate: true }
    );
    function updateAmount({
      amount,
      isBaseAmount: isBaseAmountUpdate
    }) {
      isBaseAmount.value = isBaseAmountUpdate;
      const amountToUpdate = updateAmountFromBase({
        amount,
        isBaseAmount: isBaseAmountUpdate
      });
      if (amountToUpdate) {
        formValues.value[isBaseAmountUpdate ? TradeField.QuoteAmount : TradeField.BaseAmount] = amountToUpdate;
      }
    }
    function submitLimitOrder() {
      status.setLoading();
      derivativeStore.submitLimitOrder({
        market: props.market,
        price: limitPrice.value,
        quantity: baseAmount.value,
        margin: notionalWithLeverage.value,
        orderType: orderTypeToSubmit.value,
        reduceOnly: orderTypeReduceOnly.value
      }).then(() => {
        handleAttemptPlaceOrderTrack();
        success({ title: t("trade.order_placed") });
        resetForm();
      }).catch((e) => {
        handleAttemptPlaceOrderTrack(e);
        $onError(e);
      }).finally(() => {
        status.setIdle();
      });
    }
    function submitStopLimitOrder() {
      if (!triggerPrice.value) {
        return;
      }
      status.setLoading();
      derivativeStore.submitStopLimitOrder({
        market: props.market,
        price: limitPrice.value,
        quantity: baseAmount.value,
        triggerPrice: triggerPrice.value,
        margin: notionalWithLeverage.value,
        orderType: orderTypeToSubmit.value,
        reduceOnly: orderTypeReduceOnly.value
      }).then(() => {
        handleAttemptPlaceOrderTrack();
        success({ title: t("trade.order_placed") });
        resetForm();
      }).catch((e) => {
        handleAttemptPlaceOrderTrack(e);
        $onError(e);
      }).finally(() => {
        status.setIdle();
      });
    }
    function submitMarketOrder() {
      status.setLoading();
      derivativeStore.submitMarketOrder({
        market: props.market,
        quantity: baseAmount.value,
        price: worstPriceWithSlippage.value,
        reduceOnly: orderTypeReduceOnly.value,
        orderType: formValues.value[TradeField.OrderType],
        margin: notionalWithLeverageBasedOnWorstPrice.value
      }).then(() => {
        handleAttemptPlaceOrderTrack();
        success({ title: t("trade.order_placed") });
        resetForm();
      }).catch((e) => {
        handleAttemptPlaceOrderTrack(e);
        $onError(e);
      }).finally(() => {
        status.setIdle();
      });
    }
    function submitStopMarketOrder() {
      if (!triggerPrice.value) {
        return;
      }
      status.setLoading();
      derivativeStore.submitStopMarketOrder({
        market: props.market,
        quantity: baseAmount.value,
        triggerPrice: triggerPrice.value,
        orderType: orderTypeToSubmit.value,
        price: worstPriceWithSlippage.value,
        reduceOnly: orderTypeReduceOnly.value,
        margin: notionalWithLeverageBasedOnWorstPrice.value
      }).then(() => {
        handleAttemptPlaceOrderTrack();
        success({ title: t("trade.order_placed") });
        resetForm();
      }).catch((e) => {
        handleAttemptPlaceOrderTrack(e);
        $onError(e);
      }).finally(() => {
        status.setIdle();
      });
    }
    function setDefaultFormValues() {
      formValues.value[TradeField.BaseAmount] = amountStep.value;
      formValues.value[TradeField.QuoteAmount] = priceStep.value;
      formValues.value[TradeField.LimitPrice] = lastTradedPrice.value.toFixed(
        props.market.priceDecimals,
        TRADE_FORM_PRICE_ROUNDING_MODE
      );
      formValues.value[TradeField.ReduceOnly] = false;
    }
    function resetForm() {
      resetFormValues();
      setDefaultFormValues();
    }
    function handleRequestSubmit() {
      if (highDeviation.value) {
        return modalStore.openModal({
          type: Modal.PriceDeviation
        });
      }
      if (appStore.userState.skipTradeConfirmationModal || tradingTypeMarket.value || tradingTypeLimit.value) {
        return handleSubmit();
      }
      if (!triggerPrice.value || tradingTypeStopLimit.value && !limitPrice.value) {
        return;
      }
      return modalStore.openModal({
        type: Modal.OrderConfirm
      });
    }
    function handleSubmit() {
      switch (formValues.value[TradeField.TradingType]) {
        case TradeExecutionType.LimitFill:
          return submitLimitOrder();
        case TradeExecutionType.Market:
          return submitMarketOrder();
        case "stopLimit":
          return submitStopLimitOrder();
        case "stopMarket":
          return submitStopMarketOrder();
      }
    }
    function handleAttemptPlaceOrderTrack(errorMessage) {
      const slippageTolerance = tradingTypeMarket.value ? formValues.value[TradeField.SlippageTolerance] : "";
      const postOnly = tradingTypeLimit.value && formValues.value[TradeField.PostOnly];
      const status2 = errorMessage ? OrderAttemptStatus.Error : OrderAttemptStatus.Success;
      amplitudeTracker.submitAttemptPlaceOrderTrackEvent({
        status: status2,
        postOnly,
        slippageTolerance,
        error: errorMessage,
        market: props.market.slug,
        marketType: props.market.subType,
        amount: formValues.value[TradeField.BaseAmount],
        leverage: formValues.value[TradeField.Leverage],
        orderType: formValues.value[TradeField.OrderType],
        reduceOnly: formValues.value[TradeField.ReduceOnly],
        limitPrice: formValues.value[TradeField.LimitPrice],
        tradingType: formValues.value[TradeField.TradingType],
        triggerPrice: formValues.value[TradeField.TriggerPrice]
      });
    }
    return (_ctx, _cache) => {
      const _component_PartialsTradingFormTradeExecutionTypeButtons = __nuxt_component_0;
      const _component_PartialsTradingFormOrderTypeSelect = __nuxt_component_1;
      const _component_PartialsTradingFormOrderInputs = __nuxt_component_2;
      const _component_PartialsTradingFormDebug = __nuxt_component_3;
      const _component_PartialsTradingOrderDetails = __nuxt_component_4;
      const _component_PartialsTradingFormOrderSubmit = __nuxt_component_5;
      const _component_ModalsOrderConfirmDerivative = __nuxt_component_6;
      const _component_ModalsPriceDeviation = __nuxt_component_7;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PartialsTradingFormTradeExecutionTypeButtons, { "onForm:reset": setDefaultFormValues }),
        _createVNode(_component_PartialsTradingFormOrderTypeSelect, _normalizeProps(_guardReactiveProps({ market: __props.market })), null, 16),
        _createVNode(_component_PartialsTradingFormOrderInputs, _mergeProps({
          fees: _unref(fees),
          isBuy: _unref(isBuy),
          market: __props.market,
          feeRate: _unref(feeRate),
          position: _unref(position),
          priceStep: _unref(priceStep),
          amountStep: _unref(amountStep),
          isBaseAmount: _unref(isBaseAmount),
          maxReduceOnly: _unref(maxReduceOnly),
          executionPrice: _unref(executionPrice),
          showReduceOnly: _unref(showReduceOnly),
          lastTradedPrice: _unref(lastTradedPrice),
          orderTypeReduceOnly: _unref(orderTypeReduceOnly),
          maxAmountOnOrderbook: _unref(maxAmountOnOrderbook),
          availableBalanceError: _unref(availableBalanceError),
          quoteAvailableBalance: _unref(quoteAvailableBalance),
          worstPriceWithSlippage: _unref(worstPriceWithSlippage),
          markPriceThresholdError: _unref(markPriceThresholdError),
          initialMinMarginRequirementError: _unref(initialMinMarginRequirementError)
        }, { "onUpdate:amount": updateAmount }), null, 16),
        _unref(DEBUG_CALCULATION) ? (_openBlock(), _createBlock(_component_PartialsTradingFormDebug, _normalizeProps(_mergeProps({ key: 0 }, {
          fees: _unref(fees),
          isBuy: _unref(isBuy),
          market: __props.market,
          feeRate: _unref(feeRate),
          isBaseAmount: _unref(isBaseAmount),
          liquidationPrice: _unref(liquidationPrice),
          notionalValue: _unref(notionalWithLeverage),
          notionalWithFees: _unref(notionalWithLeverageAndFees)
        })), null, 16)) : _createCommentVNode("", true),
        (_openBlock(), _createBlock(_component_PartialsTradingOrderDetails, _mergeProps({
          key: _unref(formValues)[_unref(TradeField).TradingType]
        }, {
          fees: _unref(fees),
          isBuy: _unref(isBuy),
          market: __props.market,
          feeRate: _unref(feeRate),
          slippage: _unref(slippage),
          formValues: _unref(formValues),
          executionPrice: _unref(executionPrice),
          liquidationPrice: _unref(liquidationPrice),
          orderTypeReduceOnly: _unref(orderTypeReduceOnly),
          notionalValue: _unref(notionalWithLeverage),
          notionalWithFees: _unref(notionalWithLeverageAndFees)
        }), null, 16)),
        _createVNode(_component_PartialsTradingFormOrderSubmit, _mergeProps({
          isBuy: _unref(isBuy),
          status: _unref(status),
          market: __props.market,
          hasBaseAmount: _unref(hasBaseAmount),
          highDeviation: _unref(highDeviation),
          executionPrice: _unref(executionPrice),
          maxOrdersError: _unref(maxOrdersError),
          hasTriggerPrice: _unref(hasTriggerPrice),
          orderTypeReduceOnly: _unref(orderTypeReduceOnly),
          availableBalanceError: _unref(availableBalanceError),
          markPriceThresholdError: _unref(markPriceThresholdError),
          initialMinMarginRequirementError: _unref(initialMinMarginRequirementError)
        }, { "onSubmit:request": handleRequestSubmit }), null, 16),
        _createVNode(_component_ModalsOrderConfirmDerivative, _mergeProps({
          market: __props.market,
          triggerPrice: _unref(triggerPrice),
          amount: _unref(baseAmount),
          orderType: _unref(orderTypeToSubmit),
          isReduceOnly: _unref(formValues)[_unref(TradeField).ReduceOnly],
          tradingType: _unref(formValues)[_unref(TradeField).TradingType],
          price: _unref(tradingTypeStopLimit) ? _unref(limitPrice) : void 0
        }, { "onOrder:confirmed": handleSubmit }), null, 16),
        _createVNode(_component_ModalsPriceDeviation, { "onOrder:confirmed": handleSubmit })
      ]);
    };
  }
});
