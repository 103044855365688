import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Checkbox.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Modal.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, renderSlot as _renderSlot, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex items-center justify-start gap-2" };
const _hoisted_2 = {
  key: 0,
  class: "normal-case font-semibold"
};
const _hoisted_3 = {
  key: 1,
  class: "normal-case font-semibold"
};
const _hoisted_4 = { class: "flex flex-col gap-6" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { key: 1 };
const _hoisted_7 = { key: 0 };
const _hoisted_8 = { key: 1 };
const _hoisted_9 = { key: 0 };
const _hoisted_10 = { key: 1 };
const _hoisted_11 = { key: 0 };
const _hoisted_12 = { key: 1 };
const _hoisted_13 = { class: "flex justify-between items-center gap-6" };
import { DerivativeOrderSide } from "@injectivelabs/sdk-ts";
import {
  ZERO_IN_BASE
} from "@injectivelabs/sdk-ui-ts";
import { Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Derivative",
  props: {
    isReduceOnly: Boolean,
    amount: {
      type: Object,
      required: true
    },
    market: {
      type: Object,
      required: true
    },
    orderType: {
      type: String,
      default: ""
    },
    price: {
      type: Object,
      default: ZERO_IN_BASE
    },
    tradingType: {
      type: String,
      default: ""
    },
    triggerPrice: {
      type: Object,
      default: ZERO_IN_BASE
    }
  },
  emits: ["order:confirmed"],
  setup(__props, { emit }) {
    const props = __props;
    const appStore = useAppStore();
    const modalStore = useModalStore();
    const { t } = useLang();
    const showModal = computed(() => modalStore.modals[Modal.OrderConfirm]);
    const orderTypeBuy = computed(() => {
      return [DerivativeOrderSide.TakeBuy, DerivativeOrderSide.StopBuy].includes(
        props.orderType
      );
    });
    const orderTypeTakeProfit = computed(() => {
      return [DerivativeOrderSide.TakeBuy, DerivativeOrderSide.TakeSell].includes(
        props.orderType
      );
    });
    const orderTypeStopLoss = computed(() => {
      return [DerivativeOrderSide.StopBuy, DerivativeOrderSide.StopSell].includes(
        props.orderType
      );
    });
    const tradingTypeMarket = computed(() => {
      return ["stopMarket", "market"].includes(props.tradingType.trim());
    });
    const markPriceIncrease = computed(() => {
      return orderTypeBuy.value && orderTypeStopLoss.value || !orderTypeBuy.value && orderTypeTakeProfit.value;
    });
    const { valueToString: amountToFormat } = useBigNumberFormatter(
      computed(() => props.amount),
      {
        decimalPlaces: props.market.quantityDecimals
      }
    );
    const { valueToString: priceToFormat } = useBigNumberFormatter(
      computed(() => props.price),
      { decimalPlaces: props.market.priceDecimals }
    );
    const { valueToString: triggerPriceToFormat } = useBigNumberFormatter(
      computed(() => props.triggerPrice),
      {
        decimalPlaces: props.market.priceDecimals
      }
    );
    function confirm() {
      emit("order:confirmed");
      close();
    }
    function close() {
      modalStore.closeModal(Modal.OrderConfirm);
    }
    function handleSkipTradeConfirmationModal() {
      appStore.setUserState({
        ...appStore.userState,
        skipTradeConfirmationModal: true
      });
    }
    return (_ctx, _cache) => {
      const _component_i18n_t = _resolveComponent("i18n-t");
      const _component_AppButton = __nuxt_component_0;
      const _component_AppCheckbox = __nuxt_component_1;
      const _component_AppModal = __nuxt_component_2;
      return _openBlock(), _createBlock(_component_AppModal, {
        show: _unref(showModal),
        sm: !!__props.tradingType,
        "data-cy": "price-deviation-modal",
        "onModal:closed": close
      }, {
        title: _withCtx(() => [
          _createElementVNode("h3", _hoisted_1, [
            _createElementVNode("span", {
              class: _normalizeClass(["normal-case", {
                "text-green-500": _unref(orderTypeBuy),
                "text-red-500": !_unref(orderTypeBuy)
              }])
            }, _toDisplayString(_ctx.$t(`${_unref(orderTypeBuy) ? "trade.buy" : "trade.sell"}`)), 3),
            _unref(orderTypeTakeProfit) ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(t)("trade.takeProfit")) + " " + _toDisplayString(_unref(tradingTypeMarket) ? _unref(t)("trade.market") : _unref(t)("trade.limit")), 1)) : _createCommentVNode("", true),
            _unref(orderTypeStopLoss) ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(t)("trade.stopLoss")) + " " + _toDisplayString(_unref(tradingTypeMarket) ? _unref(t)("trade.market") : _unref(t)("trade.limit")), 1)) : _createCommentVNode("", true)
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_i18n_t, {
              keypath: _unref(tradingTypeMarket) ? "trade.confirmOrderModal.descriptionMarket" : "trade.confirmOrderModal.descriptionLimit",
              tag: "p",
              class: "text-sm"
            }, _createSlots({
              verb: _withCtx(() => [
                _unref(markPriceIncrease) ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(t)("trade.confirmOrderModal.rises")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(t)("trade.confirmOrderModal.drops")), 1))
              ]),
              preposition: _withCtx(() => [
                _unref(markPriceIncrease) ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(t)("trade.confirmOrderModal.above")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_unref(t)("trade.confirmOrderModal.below")), 1))
              ]),
              triggerPrice: _withCtx(() => [
                _createElementVNode("b", null, _toDisplayString(_unref(triggerPriceToFormat)), 1)
              ]),
              triggerPriceSymbol: _withCtx(() => [
                _createElementVNode("b", null, _toDisplayString(__props.market.quoteToken.symbol), 1)
              ]),
              tradingType: _withCtx(() => [
                _unref(tradingTypeMarket) ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref(t)("trade.market").toLowerCase()), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_unref(t)("trade.limit").toLowerCase()), 1))
              ]),
              orderType: _withCtx(() => [
                _unref(orderTypeBuy) ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_unref(t)("trade.buy").toLowerCase()), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_unref(t)("trade.sell").toLowerCase()), 1))
              ]),
              amount: _withCtx(() => [
                _createElementVNode("b", null, _toDisplayString(_unref(amountToFormat)), 1)
              ]),
              amountSymbol: _withCtx(() => [
                _createElementVNode("b", null, _toDisplayString(__props.market.baseToken.symbol), 1)
              ]),
              price: _withCtx(() => [
                _createElementVNode("b", null, _toDisplayString(_unref(priceToFormat)), 1)
              ]),
              priceSymbol: _withCtx(() => [
                _createElementVNode("b", null, _toDisplayString(__props.market.quoteToken.symbol), 1)
              ]),
              _: 2
            }, [
              __props.isReduceOnly ? {
                name: "reduceOnly",
                fn: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)("trade.reduce_only").toLowerCase()), 1)
                ]),
                key: "0"
              } : void 0
            ]), 1032, ["keypath"]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_AppButton, {
                class: "bg-blue-500 text-blue-900",
                "data-cy": "confirm-order-modal-confirm-button",
                onClick: confirm
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("common.confirm")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_AppButton, {
                class: "text-red-500 bg-red-500 bg-opacity-10 font-semibold hover:text-white",
                "data-cy": "confirm-order-modal-confirm-button",
                onClick: close
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_AppCheckbox, {
                "model-value": false,
                "data-cy": "confirm-order-modal-do-not-show-toggle",
                onInput: handleSkipTradeConfirmationModal
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default", { class: "text-xs" }, () => [
                    _createTextVNode(_toDisplayString(_ctx.$t("trade.confirmOrderModal.doNotShowThisConfirmationAgain")), 1)
                  ])
                ]),
                _: 3
              })
            ])
          ])
        ]),
        _: 3
      }, 8, ["show", "sm"]);
    };
  }
});
